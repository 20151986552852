import React from "react"
import Img from "gatsby-image"
import font from "../../fonts/fonts.module.scss"
import { device } from "../device"
import { Link, useTranslation } from "gatsby-plugin-react-i18next"

import styled from "styled-components"
import Arrow from "../../images/arrow-news.svg"

const News = ({ data }) => {
  // const { t } = useTranslation(["newsletters"])
  // const sort = t('newsletter', {returnObjects: true}).sort((a, b) => b.dateformat.localeCompare(a.dateformat))

  return (
    <StyledNews>
      <Img
        fluid={data}
        style={{ width: `100%` }}
        imgStyle={{ objectFit: "cover" }}
        className="bg-img"
      />
      <div className="container">
        <Link to="/media" className="left-container">
          <div className="first">
            <img src={Arrow} className="arrow" alt="arrow" />
            {/* <h3 className={font.aGGB}>{t('newsBanner')}</h3> */}
            {/* <h3 className={font.aGGD}>{sort[0].title}</h3> */}
            <h3 className={font.aGGD}>Newsletters</h3>
          </div>
          {/* <h4 className={font.nSI}>{sort[0].date}</h4> */}
        </Link>
        <div className="right-container" />
      </div>
    </StyledNews>
  )
}

export default News

const StyledNews = styled.div`
  position: relative;

  @media ${device.desktop} {
    margin-left: 200px;
    display: block;
  }

  @media ${device.desktopL} {
    margin-left: 15.65%;
  }

  .bg-img {
    height: 400px;

    @media ${device.desktop} {
      height: unset;
    }
  }

  .container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    @media ${device.desktop} {
      display: flex;
      justify-content: center;
      flex-direction: row;
    }
  }

  .left-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    padding: 45px;

    @media ${device.tabletM} {
    }

    @media ${device.desktopL} {
      margin: 0;
      padding: 40px 0 40px 40px;
      margin-right: 30%;
    }

    .first {
      background-color: #fff9f4;
      height: 100%;
      padding: 25px;

      img {
        width: 50px;
        margin-bottom: 10px;
      }

      h3 {
        font-size: 18px;
        letter-spacing: unset;
        color: #150e04;
      }
    }

    h4 {
      font-size: 15px;
      letter-spacing: 0;
      color: #3e3028;
      background-color: #fff9f4;
      padding: 25px;
    }
  }

  .right-container {
    width: 100%;
  }
`
