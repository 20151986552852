import React from "react"
import font from "../../fonts/fonts.module.scss"
import {useTranslation} from 'gatsby-plugin-react-i18next'

import styled from "styled-components"
import { device } from "../device"

import ano1823 from "../../images/year-1823.svg"
import Img from "gatsby-image"

const Sobre = ({ json, year, dark, data }) => {
  const {t} = useTranslation([json])

  return(
    <StyledSobre dark={dark}>
      {data && 
        <Img fluid={data.background.childImageSharp.fluid} style={{ width: `100%` }} imgStyle={{ objectFit: "cover" }} className='bg-img'/>
      }
      <div className='content'>
        <h2 className={font.nSR + " sobreTitle"} dangerouslySetInnerHTML={{ __html: t('sobre.title')}}></h2>
        <h3 className={font.nSI + " sobreSubtitle"} dangerouslySetInnerHTML={{ __html: t('sobre.subtitle')}}></h3>
        <p className={font.nSR + " sobreTxt"} dangerouslySetInnerHTML={{ __html: t('sobre.text')}}></p>
        {year && 
          <img src={ano1823} alt="Ano 1823" className="ano1823"/>
        }
      </div>
    </StyledSobre>
  )
}

export default Sobre

const StyledSobre = styled.div`
      display: flex;
      flex-direction: column;
      text-align: ${props => props.dark ? 'left' : 'center'};
      position: relative;
      padding: ${props => !props.dark && '80px 25px 40px'};
      max-width: ${props => !props.dark && '700px'};
      margin: 0 auto;
      width: ${props => props.dark && '100%'};
      max-height: ${props => props.dark && '1024px'};

    @media ${device.desktop}{
      max-width:unset;
      margin:unset;
      justify-content: flex-end;
      width: ${props => !props.dark && '50%'};
      margin: ${props => props.dark ? '10vh auto 0' : '25vh auto 0'};
      padding: ${props => props.dark && '80px 0 40px 200px'};
    }

    @media ${device.desktopL}{
      width: ${props => !props.dark && '35%'};
      padding-left: ${props => props.dark && '15.65%'}
    }

    .content{
      position: ${props => props.dark && 'absolute'};
      top: ${props => props.dark && '50%'};
      left: ${props => props.dark && '50%'};
      transform: ${props => props.dark && 'translate(-50%, -50%)'};
      width: ${props => props.dark && '100%'};
      padding: ${props => props.dark && '0 50px'};
      
      @media ${device.desktop}{
        width: ${props => props.dark && '80%'};
        padding: ${props => props.dark && '0 0 0 200px'};
      }

      @media ${device.desktopL}{
        width: ${props => props.dark && '65%'};
        padding: ${props => props.dark && '0 0 0 15.65%'};
      }
    }

    .bg-img{
      height:500px;

      @media ${device.desktop}{
        height:100%;
      }

      &::after{
        content:'';
        width:100%;
        height:100%;
        position:absolute;
        top:0;
        background:#fff;
        opacity:0.9;
      }
    }

    .sobreTitle{
      z-index: 1;
      color: ${props => props.dark ? '#3e3028' : '#fff8f4'};
      font-size: 35px;

      @media ${device.desktop}{
          font-size: 46px;
      }
    }

    .sobreTxt{
      z-index: 1;
      color: ${props => props.dark ? '#3e3028' : '#fff8f4'};
      font-size: 18px;
      overflow-y:auto;
      -webkit-overflow-scrolling: touch;
      max-height:${props => props.dark ? '250px' : '350px'};
      padding: ${props => props.dark ? '0 25px 0 0' : '0 25px'};
      letter-spacing:-0.5px !important;

      @media ${device.desktop}{
        font-size: ${({dark}) => dark ? '23px' : '20px'};
        overflow-y:auto;
       -webkit-overflow-scrolling: touch;
        max-height:${props => props.dark && '350px'};
      }
    }

    .sobreTxt::-webkit-scrollbar {
      width: 3px;
      background-color: ${props => props.dark ? '#fff' : '#3e3028'};
    }
    .sobreTxt::-webkit-scrollbar-thumb {
        height: 6px;
        background-clip: padding-box;
        background-color: ${props => props.dark ? '#3e3028' : '#fff'};
        box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05), inset 1px 1px 0px rgba(0, 0, 0, 0.05);
    }
    .sobreTxt::-webkit-scrollbar-button {
        width: 0;
        height: 0;
        display: none;
    }
    .sobreTxt::-webkit-scrollbar-corner {
        background-color: transparent;
    }

    .sobreSubtitle{
        color: #fa4f4f;
        margin-bottom: 2em;
        z-index: 1;
    }

    .ano1823{
      position: absolute;
      top: 10%;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
      z-index: -1;

      @media ${device.desktop}{
        top:0;
      }
    }
`