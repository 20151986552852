import "./latinize"

export default function linkify(regiao, nome){
  return regiaoTranslator(regiao).concat("/").concat(nome).replace(/\s+/g, '-').toLowerCase().latinize();
}

function regiaoTranslator(string){
  switch(string){
    case "D.O.C. - Bairrada":
      return("bairrada");
    case "D.O.C. - Dão":
      return("dao");
    case "D.O.C. - Douro":
      return("douro");
    case "D.O.C. - Moscatel de Setubal":
      return("setubal");
    case "D.O.C. - Palmela":
      return("palmela");
    case "D.O.C. - Porto":
      return("porto");
    case "D.O.C. - Do Tejo":
      return("tejo");
    case "D.O.C. - Vinho Verde":
      return("verde");
    case "Regional - Alentejano":
      return("regionais_alentejanos");
    case "Regional - Mesa":
      return("outros_mesa");
    case "Regional - Minho":
      return("regionais_minho");
    case "Regional - Península de Setúbal":
      return("regionais_setubal");
    case "Regional - Tejo":
      return("regionais_tejo");
    default:
      return("");
  }
}