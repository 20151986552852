import React from "react"
import Img from "gatsby-image"
import font from "../../fonts/fonts.module.scss"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { useTranslation, I18nextContext } from "gatsby-plugin-react-i18next"

import { device } from "../device"

import styled from "styled-components"
import Div100vh from "react-div-100vh"

import filter from "../../images/fullpages/filter.svg"
import logo from "../../images/logos/saven_logo.svg"
import logoLC from "../../images/logos/lua_logo.svg"
import logoAP from "../../images/logos/azul_logo.svg"
import logoSmall from "../../images/logos/lua_small_logo_dark.svg"
import logoSmallAP from "../../images/logos/azul_small_logo.svg"
import visit from "../../images/fullpages/visit.svg"
import visitD from "../../images/fullpages/visit_dark.svg"

const HomeScreen = ({
  data,
  saven,
  luacheia,
  azulportugal,
  json,
  ...props
}) => {
  const { t } = useTranslation([json])
  const activeLanguage = React.useContext(I18nextContext)

  return (
    <StyledHome>
      <Img
        fluid={data}
        style={{ height: `100%`, position: "absolute" }}
        imgStyle={{ objectFit: "cover" }}
        className="background"
      />
      <img src={filter} alt="Filtro de imagem" className="background" />
      {saven && (
        <div className="middleCol">
          <img src={logo} alt="Logo da Saven" className="logo" />
          <div className="btns">
            <a
              href="https://luacheia-saven.pt/"
              className="homeBtn"
              alt="luacheia-saven"
              target="__blank"
              rel="noopener noreferrer nofollow"
            >
              <h3 className={font.nSR}>{t("homepage:intro.header")}</h3>
            </a>
          </div>
          {props.catalog && activeLanguage.language === "pt" && (
            <div className="btns" style={{ marginTop: "20px" }}>
              <a
                href="/assets/catalogs/natal_saven_2020.pdf"
                className="homeBtn"
                alt="Catálogo de Natal 2020"
                target="__blank"
                rel="noopener noreferrer nofollow"
              >
                <h3 className={font.nSR}>Catálogo de Natal 2020</h3>
              </a>
            </div>
          )}
          <AnchorLink
            to={
              activeLanguage.language === activeLanguage.defaultLanguage
                ? "/#visit"
                : activeLanguage.language + "/#visit"
            }
            className="visit"
          >
            <img src={visit} alt="Visitar o Site" />
            <p className={font.nSBI + " small"}>{t("homepage:intro.visit")}</p>
          </AnchorLink>
        </div>
      )}
      {luacheia && (
        <div className="middleCol">
          <img src={logoLC} alt="Logo da Lua Cheia" className="logo" />
          <AnchorLink
            to={
              activeLanguage.language === activeLanguage.defaultLanguage
                ? "/lua_cheia/#visit"
                : activeLanguage.language + "/lua_cheia/#visit"
            }
            className="visit"
          >
            <img src={visitD} alt="Visitar o Site" />
            <img
              src={logoSmall}
              alt="Logo da Lua Cheia"
              className="smallLogo"
            />
          </AnchorLink>
        </div>
      )}
      {azulportugal && (
        <div className="middleCol">
          <img src={logoAP} alt="Logo da Azul Portugal" className="logo" />
          <AnchorLink
            to={
              activeLanguage.language === activeLanguage.defaultLanguage
                ? "/azul_portugal/#visit"
                : activeLanguage.language + "/azul_portugal/#visit"
            }
            className="visit"
          >
            <img src={visitD} alt="Visitar o Site" />
            <img
              src={logoSmallAP}
              alt="Logo da Azul Portugal"
              className="smallLogo"
            />
          </AnchorLink>
        </div>
      )}
    </StyledHome>
  )
}

export default HomeScreen

const StyledHome = styled(Div100vh)`
  width: 100%;
  position: relative;
  overflow: hidden;
  z-index: 99;

  .background {
    position: absolute;
    min-width: 100%;
    min-height: 100%;
    z-index: -1;
    top: 0;
  }

  .middleCol {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 3;

    .logo {
      width: 70%;
      max-width: 400px;
    }

    .btns {
      display: flex;
      flex-direction: column;
      width: 90%;
      max-width: 400px;
      margin-top: 80px;

      @media ${device.mobileXL} {
        width: 70%;
      }

      .homeBtn {
        background: transparent;
        border-radius: 0;
        border: 1px solid #fff;
        user-select: none;
        outline: none;
        margin-bottom: 3vh;
        height: 50px;
        color: #fff;
        transition: background-color 0.3s;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
          cursor: pointer;
          background-color: #fff;
          color: #3e3028;
        }
      }

      h3 {
        font-size: 20px;
        letter-spacing: 0;

        @media ${device.tablet} {
          font-size: 26px;
        }
      }
    }

    .visit {
      position: absolute;
      bottom: -140px;
      /* transform: translateY(65%); */
      display: flex;
      justify-content: center;
      animation: slide-in-blurred-bottom 1.6s cubic-bezier(0.23, 1, 0.32, 1)
        both;
      @keyframes slide-in-blurred-bottom {
        0% {
          transform: translateY(1000px) scaleY(2.5) scaleX(0.2);
          transform-origin: 50% 100%;
          filter: blur(40px);
          opacity: 0;
        }
        100% {
          transform: translateY(0) scaleY(1) scaleX(1);
          transform-origin: 50% 50%;
          filter: blur(0);
          opacity: 1;
        }
      }

      .small {
        color: #3e3028;
        text-decoration: underline;
        position: absolute;
        top: 15%;
      }
    }

    .smallLogo {
      position: absolute;
      top: 7%;
    }
  }
`
